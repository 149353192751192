<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <!--<h3>Log Link Fraude </h3>-->
            <CRow class="justify-content-between">
              <CCol col="8"><h3>Log Link Fraude</h3></CCol>
              <CCol col="3"
                ><CButton
                  color="success"
                  variant="outline"
                  @click="getLogFraude()"
                  class="m-1"
                >
                  Actualizar Tabla
                </CButton></CCol
              >
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="10"
              :pagination="{ doubleArrows: false, align: 'center' }"
              column-filter
              @page-change="pageChange"
            >
              <template #cantidad_mensajes="{ item, index }">
                <td>
                  {{ creditosConversorMoneda(item.cantidad_mensajes) }}
                </td>
              </template>
              <template #accion="{ item, index }">
                <td>
                  <CButton size="sm" color="danger" @click="openmodal(item)">
                    Cancelar Envio
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            Cargue Cancelado Correctamente
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
      <CModal
        :show.sync="modal_cancelar_envio"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="sm"
        color="dark"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Cancelar Los Envios Esta Seguro ?
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            {{ form.mensaje_cancela }}
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Cancelar Envios</h6>
          <CButtonClose
            @click="modal_cancelar_envio = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton @click="modal_cancelar_envio = false" color="danger"
            >Cancel</CButton
          >
          <CButton color="success" @click="cancelarEnvio">Aceptar</CButton>
        </template>
      </CModal>
    </div>
  </CContainer>
</template>

<script>
export default {
  //usuarios/logsfraude/{id}
  name: "LogFraudeLink",
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      items: [],
      items_filter: [],
      fields: [
        { key: "login", label: "Email" },
        {
          key: "mensaje_enviar",
          label: "Mensaje a Enviar",
          _classes: "font-weight-bold",
        },
        { key: "cantidad_mensajes", label: "Cantidad Mensajes" },
        { key: "fecha_prog_envio", label: "Fecha de Programacion" },
        { key: "fecha_sistema", label: "Fecha Sistema" },
        {
          key: "accion",
          label: "",
          _style: { width: "1%" },
          sorter: false,
          filter: false,
        },
      ],
      activePage: 1,
      usuario: {
        name: "",
      },
      modal_cancelar_envio: false,
      form: {
        id: 0,
        mensaje_cancela: "",
      },
    };
  },
  methods: {
    getLogFraude() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("sms/logsfraude")
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });
          //console.log(this.items)
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    cancelarEnvio(evt) {
      evt = evt ? evt : window.event;
      this.modal_cancelar_envio = false;
      evt.target.disabled = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("sms/logsfraude", {
          idproyecto: this.form.id,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            this.resetfrom();
            this.mostrarNotificacion++;
            this.getLogFraude();
          } else {
            //this.mostrarNotificacionError++;
          }
        })
        .finally(() => {
          this.$store.dispatch("auth/loading_http", "hidden");
          evt.target.disabled = false;
        });
    },
    openmodal(item) {
      this.modal_cancelar_envio = true;
      this.form.id = item.idproyecto;
      this.form.mensaje_cancela = item.mensaje_enviar;
    },
    resetfrom() {
      this.form.id = 0;
      this.form.mensaje_cancela = "";
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    creditosConversorMoneda(value) {
      const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatterDolar.format(value).replace("$", "");
    },
  },
  mounted() {
    setTimeout(() => {
      this.getLogFraude();
    }, 200);
  },
};
</script>
